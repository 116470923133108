<template>
  <v-sheet v-if="activePerson">
    <div class="text-h6">In Case of Emergency Contact Info</div>
    <v-list-item v-if="!editICEName">
      <v-list-item-icon>
        <v-icon>mdi-account</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ activePerson.ICE_Name }}</v-list-item-title>
        <v-list-item-subtitle>Contact Name</v-list-item-subtitle>
        <div>
        </div>
      </v-list-item-content>
      <v-list-item-action>
        <v-row align="center"
               justify="center" v-if="isEditable">
          <v-col>
            <!--            Reserved for Future visibility of per user item information sharing-->
          </v-col>
          <v-col>
            <app-btn fab @click="editICEName = !editICEName">
              <v-icon>{{ editICEName ? `mdi-content-save` : `mdi-pencil` }}</v-icon>
            </app-btn>
          </v-col>
        </v-row>
      </v-list-item-action>
    </v-list-item>
    <div v-if="editICEName">
      <app-text-field v-model="activePerson.ICE_Name" label="Contact Name"/>
      <v-divider/>
      <v-row class="justify-end">
        <v-col>
          <v-btn small @click="editMobile = false">Cancel</v-btn>
          <v-btn small @click="save">Save</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-list-item v-if="!editICERelationship">
      <v-list-item-icon>
        <v-icon>mdi-account</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ activePerson.ICE_Relationship }}</v-list-item-title>
        <v-list-item-subtitle>Relationship</v-list-item-subtitle>
        <div>
        </div>
      </v-list-item-content>
      <v-list-item-action>
        <v-container>
          <v-row align="center"
                 justify="center" v-if="isEditable">
            <v-col>
              <!--             Reserved for Future visibility of per user item information sharing-->
            </v-col>
            <v-col>
              <app-btn fab @click="editICERelationship = !editICERelationship">
                <v-icon>{{ editICERelationship ? `mdi-content-save` : `mdi-pencil` }}</v-icon>
              </app-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-list-item-action>
    </v-list-item>
    <div v-if="editICERelationship">
      <app-text-field v-model="activePerson.ICE_Relationship" label="Relationship to individual"/>
      <v-divider/>
      <v-row class="justify-end">
        <v-col>
          <v-btn small @click="editMobile = false">Cancel</v-btn>
          <v-btn small @click="save">Save</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-list-item v-if="!editMobile">
      <v-list-item-icon>
        <v-icon>mdi-phone</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ activePerson.ICE_ContactNumber }}</v-list-item-title>
        <v-list-item-subtitle>Contact Number</v-list-item-subtitle>
        <div>
        </div>
      </v-list-item-content>
      <v-list-item-action>
        <v-container v-if="isEditable">
          <v-row align="center"
                 justify="center">
            <v-col>
              <!--             Reserved for Future visibility of per user item information sharing-->
            </v-col>
            <v-col>
              <app-btn fab @click="editMobile = !editMobile">
                <v-icon>{{ editMobile ? `mdi-content-save` : `mdi-pencil` }}</v-icon>
              </app-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-list-item-action>
    </v-list-item>
    <div v-if="editMobile">
      <app-text-field v-model="activePerson.ICE_ContactNumber" label="Best Contact Number"/>
      <v-divider/>
      <v-row class="justify-end">
        <v-col>
          <v-btn small @click="editMobile = false">Cancel</v-btn>
          <v-btn small @click="save">Save</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
import {mapState} from "vuex";
import AppBtn from "@/components/app/Btn";
import {PersonHttp} from "@/http/PersonHttp";
import AppTextField from "@/components/app/TextField";
import {isAuthorised} from "@/util/helpers";

export default {
  name: "ICEContactDetailsPage",
  components: {AppTextField, AppBtn},
  computed: {
    ...mapState("person", ["activePerson", "loggedInPerson"]),
    ...mapState("user", ["accountRoles"]),
    isEditable() {
      return this.activePerson._id === this.loggedInPerson._id || isAuthorised(this.accountRoles,["rootAdmin","admin"])
    }
  },
  data() {
    return {
      editICEName: false,
      editICERelationship: false,
      editMobile: false,
    }
  },
  methods: {
    save() {
      this.editICEName = false
      this.editICERelationship = false
      this.editMobile = false
      const data = this.activePerson
      const id = this.activePerson._id
      delete data._id
      PersonHttp.updatePersonDetails(id, data)
      this.activePerson._id = id
    },
  }
}
</script>

<style scoped>

</style>